import {VariantProps} from 'class-variance-authority'

import cn from '@/app/[locale]/(website)/components/utils/cn'

import {heroSubtitleClasses} from './HeroClasses'

interface HeroProps extends VariantProps<typeof heroSubtitleClasses> {
    children: React.ReactNode
    className?: string
}

export default function HeroSubtitle({children, variant, position, className}: HeroProps) {
    const heroClass = cn(heroSubtitleClasses({variant, position}), className)
    return <p className={heroClass}>{children}</p>
}
