'use client'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {useTranslations} from 'next-intl'
import {useCallback, useState} from 'react'

import cn from '@/app/[locale]/(website)/components/utils/cn'
import {Link, Pathnames, usePathname} from '@/i18n/routing'

import HamburgerMenu from '../icons/hamburger'
import Logo from '../icons/logo'
import LocaleSwitcher from '../LocaleSwitcher'
import ThemeSwitch from '../ThemeSwitch'
import {buttonClasses} from '../ui/ButtonClasses'
import ToFluxButton from '../ui/ToFluxButton'
import {navLinks} from '../utils/getNavLinks'
import NavCompanyMenu from './NavCompanyMenu'
import NavProductMenu from './NavProductMenu'
import RenderMobileNav from './renderMobileNav'

interface NavigationLinkProps {
    href: Pathnames | {href: Pathnames; params?: any}
    className?: string
    children: React.ReactNode
}

function NavigationLink({href, className, children, ...props}: NavigationLinkProps) {
    const pathname = usePathname()
    const isActive = href === pathname

    return (
        <NavigationMenu.Link asChild active={isActive}>
            <Link
                href={href as any}
                className={`${className || ''} NavigationMenuLink ${isActive ? 'active' : ''}`}
                {...props}
            >
                {children}
            </Link>
        </NavigationMenu.Link>
    )
}

export default function Header() {
    const t = useTranslations('Nav')

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const toggleMenu = useCallback(() => {
        setMobileMenuOpen((open) => !open)
    }, [])

    const closeMenu = useCallback(() => {
        setMobileMenuOpen(false)
        resetHamburgerState()
    }, [])

    const resetHamburgerState = () => {
        const hamburgerButton = document.querySelector('button[aria-pressed]')
        if (hamburgerButton) {
            hamburgerButton.setAttribute('aria-pressed', 'false')
        }
    }

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            toggleMenu()
            const pressed = event.currentTarget.getAttribute('aria-pressed') === 'true'
            event.currentTarget.setAttribute('aria-pressed', (!pressed).toString())
        },
        [toggleMenu],
    )

    return (
        <header className="fixed backdrop-blur-lg bg-white/30 dark:bg-white/5 top-0 left-0 w-full border-b border-b-slate-200/50 dark:border-b-slate-200/5 h-[var(--navigation-height)] z-[var(--layer-navigation)]">
            <NavigationMenu.Root
                className="mx-auto max-w-7xl w-full pl-[var(--page-padding-left)] pr-[var(--page-padding-right)]"
                ata-orientation="horizontal"
            >
                <NavigationMenu.List className="flex flex-row items-center gap-1">
                    <NavigationMenu.Item className="mr-auto lg:mr-6">
                        <Link
                            href="/"
                            className="flex items-center h-[var(--navigation-height)]"
                            role="link"
                            aria-label={t('topLevel.navigateHome')}
                            rel="noopener"
                            onClick={closeMenu}
                        >
                            <Logo className="size-8 mr-2" />
                            <span className="text-lg font-semibold text-tertiary dark:text-dark-tertiary">
                                {t('topLevel.flux')}
                            </span>
                        </Link>
                    </NavigationMenu.Item>

                    <NavProductMenu className="hidden lg:block" />

                    <NavigationMenu.Item className="hidden lg:block">
                        <NavigationLink
                            className={`group ${buttonClasses({
                                variant: 'navigation',
                                size: 'medium',
                                shape: 'rounded',
                                width: 'full',
                            })}`}
                            href={navLinks.customers}
                        >
                            {t('topLevel.customers')}
                        </NavigationLink>
                    </NavigationMenu.Item>

                    <NavigationMenu.Item className="hidden lg:block">
                        <NavigationLink
                            className={`group ${buttonClasses({
                                variant: 'navigation',
                                size: 'medium',
                                shape: 'rounded',
                                width: 'full',
                            })}`}
                            href={navLinks.changelog}
                        >
                            {t('topLevel.changelog')}
                        </NavigationLink>
                    </NavigationMenu.Item>

                    <NavCompanyMenu className="hidden lg:block" />

                    <NavigationMenu.Item className="hidden lg:block">
                        <NavigationLink
                            className={`group ${buttonClasses({
                                variant: 'navigation',
                                size: 'medium',
                                shape: 'rounded',
                                width: 'full',
                            })}`}
                            href={navLinks.contact}
                        >
                            {t('topLevel.contact')}
                        </NavigationLink>
                    </NavigationMenu.Item>

                    <NavigationMenu.Item className="ml-auto hidden lg:block">
                        <LocaleSwitcher />
                    </NavigationMenu.Item>

                    <NavigationMenu.Item className="hidden lg:block">
                        <ThemeSwitch />
                    </NavigationMenu.Item>

                    <NavigationMenu.Item>
                        <NavigationLink
                            className={`group ${buttonClasses({
                                variant: 'vibrant',
                                size: 'medium',
                                shape: 'round',
                                width: 'full',
                            })}`}
                            href={navLinks.contact}
                        >
                            {t('topLevel.signUp')}
                        </NavigationLink>
                    </NavigationMenu.Item>

                    <NavigationMenu.Item>
                        <ToFluxButton
                            label={t('topLevel.openApp')}
                            title={t('topLevel.loginTitle')}
                            url="https://flux.healthcare"
                            ariaLabel={t('topLevel.loginLabel')}
                        />
                    </NavigationMenu.Item>

                    <NavigationMenu.Item className="lg:hidden">
                        <button
                            type="button"
                            className={buttonClasses({
                                variant: 'hamburger',
                            })}
                            aria-pressed="false"
                            onClick={handleClick}
                        >
                            <span className="sr-only text-tertiary dark:text-dark-tertiary">
                                {mobileMenuOpen
                                    ? t('topLevel.mobilemenuclose')
                                    : t('topLevel.mobilemenuopen')}
                            </span>
                            <HamburgerMenu />
                        </button>
                    </NavigationMenu.Item>

                    <li
                        className={cn(
                            'flex h-full lg:hidden transition-[visibility]',
                            mobileMenuOpen ? 'visible' : 'invisible delay-500',
                        )}
                    >
                        <RenderMobileNav
                            mobileMenuOpen={mobileMenuOpen}
                            onCloseMenu={closeMenu}
                        />
                    </li>
                </NavigationMenu.List>
            </NavigationMenu.Root>
        </header>
    )
}
