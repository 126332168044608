import {VariantProps} from 'class-variance-authority'

import cn from '@/app/[locale]/(website)/components/utils/cn'

import {heroTitleClasses} from './HeroClasses'

interface H1Props extends VariantProps<typeof heroTitleClasses> {
    children: React.ReactNode
    className?: string
}

export default function H1Title({children, variant, position, className}: H1Props) {
    const heroClass = cn(heroTitleClasses({variant, position}), className)
    return <h1 className={heroClass}>{children}</h1>
}
