import {VariantProps} from 'class-variance-authority'

import cn from '@/app/[locale]/(website)/components/utils/cn'

import {heroClasses} from './HeroClasses'

interface HeroProps extends VariantProps<typeof heroClasses> {
    children: React.ReactNode
    className?: string
}

export default function Hero({children, variant, className}: HeroProps) {
    const heroClass = cn(heroClasses({variant}), className)
    return <div className={heroClass}>{children}</div>
}
